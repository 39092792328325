export const NAVIGATION = [
  {
    name: 'navigation.home',
    link:'/',
  },
  {
    name:'navigation.services',
    link:'/services',
  },
  // {
  //   name:'navigation.project',
  //   link:'/projects',
  // },
  {
    name:'navigation.projects',
    link:'/projects',
  },
  {
    name:'navigation.careers',
    link:'/careers',
  },
  {
    name:'navigation.about_us',
    link:'/about-us',
  },
  {
    name:'navigation.contact_us',
    link:'/contact-us',
  }
]
