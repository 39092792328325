export const LANGUAGE = {
  en: {
    key: 'en',
    name: 'language.en'
  },
  vi: {
    key: 'vi',
    name: 'language.vi'
  },
}
