import React from 'react';
import { Link } from "gatsby";
import { useIntl } from "gatsby-plugin-intl";
import { LANGUAGE } from '../../types/language';

const TransitionAniLink = React.forwardRef(({ to, children, ...rest }, ref) => {
  const intl = useIntl();
  const link = intl.locale === LANGUAGE.en.key ? `${to}` : `/${intl.locale}${to}`;

  return (
    <Link ref={ref} {...rest} to={link}>
      {children}
    </Link>
  )
})

export default TransitionAniLink;
